import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ServerDataProvider } from "./state/serverDataContext";

const dataCache = window.__SERVER_DATA__;
const _window = window;

let root = createRoot(document.getElementById("root"));
root.render(<App _window={_window} history={_window.history} />);

