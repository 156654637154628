import React, { useEffect, useState } from 'react';
import { abandoned_baskets } from '../../service/basket'
import YMDPicker from '../../components/ymd_picker'
import '../enquiries/style.css';
import dayjs from 'dayjs';

const AbandonedBaskets = ({ user_token, authorize }) => {

    const [d_view, setDataView] = useState(null);

    const [count, setCount] = useState(null)
    const [count_booked, setBookedCount] = useState(null)

    const [prodType, setProdType] = useState(null)

    let today_m = dayjs();   
    let dfrom = today_m.subtract(5, 'days');
    let from_date = dfrom.format('YYYY-MM-DD');
    let to_date = today_m.format('YYYY-MM-DD');

    const [selected_from_date, setSelectedFromDate] = useState(from_date)
    const [selected_to_date, setSelectedToDate] = useState(to_date)

    useEffect(() => {
        authorize(3);


        defaultView();

    }, [])

    const defaultView = () => {
        let today_m = dayjs();

        let dfrom = today_m.subtract(5, 'days');
        

        let from_date = dfrom.format('YYYY-MM-DD') + ' 00:00:00'
        let to_date = today_m.format('YYYY-MM-DD') + ' 23:59:59'

        let f = "id created reference email_address lead_name contact_number cost booked type last_sent sent"
        let filters = []
        filters.push({ "param_var_name": "$date_from", "param_name": "date_from", "param_type": "String!", "value": from_date })
        filters.push({ "param_var_name": "$date_to", "param_name": "date_to", "param_type": "String!", "value": to_date })

        abandoned_baskets(user_token, f, filters).then((r) => {
            basket_result(r);
        });
    }

    const filter = () => {
        let filters = []

        if (selected_from_date == null) {
            return
        }

        if (selected_to_date == null) {
            return
        }

        let from_date = selected_from_date + ' 00:00:00';
        let to_date = selected_to_date;
        if (to_date == null) {
            let today_m = dayjs()
            to_date = today_m.format('YYYY-MM-DD')
        }
        to_date = to_date + ' 23:59:59';

        let f = "id created reference email_address lead_name contact_number cost booked type last_sent sent"

        filters.push({ "param_var_name": "$date_from", "param_name": "date_from", "param_type": "String!", "value": from_date })
        filters.push({ "param_var_name": "$date_to", "param_name": "date_to", "param_type": "String!", "value": to_date })

        if (prodType != null) {
            filters.push({ "param_var_name": "$type", "param_name": "type", "param_type": "Int", "value": prodType })
        }


        abandoned_baskets(user_token, f, filters).then((r) => {
            basket_result(r);
        });

    }

    const reset_filters = () => {

        setSelectedFromDate(null);
        setSelectedToDate(null);
        defaultView();
    }

    const updateProductType = (value) => {
        if (value == "") {
            setProdType(null);
        }
        else {
            setProdType(Number(value));
        }
    }

    const basket_result = (r) => {
        if (r == null) {
            return
        }
        if (r.errors != null) {
            if (r.errors.length > 0) {
                alert(r.errors[0].message)
                return
            }
            alert("Unexpected Error Contact HWT support...")
            return
        }
        if (r.sessions_communications.error != null) {
            alert(r.sessions_communications.error.message)
            return
        }
        setDataView(r.sessions_communications.result)
    }


    const change_from_date = (v) => {
        setSelectedFromDate(v)
    }

    const change_to_date = (v) => {
        setSelectedToDate(v)
    }

    let cnt = 0;
    let cntBook = 0;
    let cntDel = 0;

    let tbl_view = null;
    if (d_view != null) {


        cnt = d_view.length;

        let rows = []

    
        for (let x of d_view) {

            let date_of_basket = dayjs(x.created, 'YYYY-MM-DD HH:mm');
            let str_bask_date = date_of_basket.format('DD MMMM YYYY HH:mm');

            let last_sent = dayjs(x.last_sent, 'YYYY-MM-DD HH:mm');
            let str_last_sent = last_sent.format('DD MMMM YYYY HH:mm');

            let colorStyle = ''
    
            let bookLbl = '-';
            if (x.booked == 1) {
                bookLbl = 'Booked'
                colorStyle = '#66d188';
                cntBook++;
            }

            let ptype = ''
            if (x.type == 0) {
                ptype = 'Beach'
            }
            else if (x.type == 1) {
                ptype = 'City'
            }
            else if (x.type == 2) {
                ptype = 'Package'
            }

            cntDel += x.sent;


            rows.push(<tr style={{ backgroundColor: colorStyle }}>
                <td>
                    {x.reference}
                </td>
                <td>
                    {x.email_address}
                </td>
                <td>
                    {x.contact_number}
                </td>
                <td>
                    {x.lead_name}
                </td>
                <td>
                    {x.cost}
                </td>
                <td>
                    {ptype}
                </td>
                <td>
                    {str_bask_date}
                </td>
                <td>
                    {str_last_sent}
                </td>        
                <td>
                    {x.sent}
                </td>
                <td>
                    <a href={'/basket/view?bref=' + x.reference}>View</a>
                </td>
            </tr>)
        }

        tbl_view = <table>
            <thead>
                <td>
                    Basket Reference
                </td>
                <td>
                    Email Address
                </td>
                <td>
                    Contact Number
                </td>
                <td>
                    Lead Name
                </td>
                <td>
                    Cost
                </td>
                <td>
                    Product Type
                </td>
                <td>
                    Created
                </td>
                <td>
                    Sent
                </td>     
                <td>
                    Qty Sent
                </td>
                <td>

                </td>
            </thead>
            <tbody>
                {rows}
            </tbody>
        </table>
    }

    return (
        <section className='aboutcontent-box'>

            <div className='container'>
                {/* <div dangerouslySetInnerHTML={{ __html: data.content }} /> */}


                <div className='fitler_sec'>



                    <div style={{ height: '105px' }}>
                        <div>
                            <div className='filter_field' style={{ marginBottom: '15px' }}>
                                <div>
                                    Created
                                </div>
                                <div>
                                    <YMDPicker id='sel_bk_from' key='sel_bk_from_k' initalValue={selected_from_date} onChange={(value) => change_from_date(value)}></YMDPicker>
                                </div>
                            </div>
                            <div className='filter_field' style={{ marginBottom: '15px' }}>
                                <div>
                                    To
                                </div>
                                <div>
                                    <YMDPicker id='sel_bk_to' key='sel_bk_to_k' initalValue={selected_to_date} onChange={(value) => change_to_date(value)}></YMDPicker>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='filter_field'>
                        <div>
                            Product Type
                        </div>
                        <div>
                            <select onChange={(e) => updateProductType(e.target.value)}>
                                <option value="">All</option>
                                <option value="-1">Dynamic Package</option>
                                <option value="0">Beach</option>
                                <option value="1">City</option>
                                <option value="2">Package</option>
                            </select>
                        </div>
                    </div>

                    <div className='filter_field'>
                        <div>
                            <button onClick={() => filter()}>Filter</button>
                            <button onClick={() => reset_filters()}>Reset</button>
                        </div>
                    </div>

                </div>

       
                <div style={{width: '100%', display: 'flex', justifyContent: 'center', marginBottom: '15px'}}>
                    <div style={{display: 'flex', justifyItems: 'center'}}>
                        Baskets:  {cnt} 
                    </div>
                    <div style={{display: 'flex', justifyItems: 'center', marginLeft: '25px'}}>
                        Delivered {cntDel} 
                    </div>
                    <div style={{marginLeft: '25px'}}>
                        Booked {cntBook} 
                    </div>
               
                </div>

                <div className='booking_tbl'>

               

                    {tbl_view}
                </div>

            </div>
        </section>
    );
};

export default AbandonedBaskets;
